<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Achievement Detail Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-4">
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Module Name</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="module"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id !== module.id"
                    :loading="!modules.length"
                    @option:selected="
                      FilterData(module, employee, state, status)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Assessor Name</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="employee"
                    :options="employees"
                    :clearable="false"
                    :selectable="(options) => options.id != employee.id"
                    :loading="!employees.length"
                    @option:selected="
                      FilterData(module, employee, state, status)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">State</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="state"
                    :options="stateList"
                    :clearable="false"
                    :selectable="(options) => options !== state"
                    @option:selected="
                      FilterData(module, employee, state, status)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <div class="me-2">
                <label class="col-form-label ms-1 me-1">Status</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="status"
                    :options="statusList"
                    :clearable="false"
                    :selectable="(options) => options !== status"
                    @option:selected="
                      FilterData(module, employee, state, status)
                    "
                  >
                  </v-select>
                </div>
              </div>
              <div @click="refreshData" class="icon-css pt-4 mt-2">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="datatable"
                style="width: 2000px"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Module Name</th>
                    <th>Training Date</th>
                    <th>Assessor Name</th>
                    <th>Business Unit</th>
                    <th>Assessment date</th>
                    <th>Due Date(Deadline)</th>
                    <th style="width: 200px">Assessor Finished Date</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Trainees Name</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(data, index) in assessment_result" :key="index">
                    <td>{{ ++index }}</td>
                    <td>
                      {{
                        data.assessment_before_one_weeks.monthly_plan.module
                          .name
                      }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.training_date }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.assessor.name }}
                    </td>
                    <td>
                      {{
                        checkBusinessUnit(
                          data?.assessment_before_one_weeks?.assessor_id
                        )
                      }}
                    </td>
                    <td>
                      {{
                        assessmentDate(
                          data.assessment_before_one_weeks.assessment_due_date
                        )
                      }}
                    </td>
                    <td>
                      {{ data.assessment_before_one_weeks.assessment_due_date }}
                    </td>
                    <td>
                      {{ finishedDate(data.created_at, data.updated_at) }}
                    </td>
                    <td>
                      {{ data.state }}
                    </td>
                    <td>
                      {{ checkStatus(data.status) }}
                    </td>
                    <td>
                      <template
                        v-for="(trainer, index) in data
                          .assessment_before_one_weeks.monthly_plan.trainers"
                        :key="index"
                      >
                        {{
                          index ==
                          data?.assessment_before_one_weeks?.monthly_plan
                            ?.trainers.length -
                            1
                            ? trainer?.user?.name
                            : trainer?.user?.name + ", "
                        }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Program Timeline
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";

export default {
  components: {
    badgeSuccess,
    badgeDanger,
    vSelect,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment_result: [],
      employees: [],
      employee: "",
      modules: [],
      module: "",
      status: "",
      state: "",
      statusList: ["Completed", "Expired", "Waiting"],
      stateList: ["Active", "Expire"],
      title: "Achievement Detail Report Lists",
      headers: [
        "No.",
        "Module Name",
        "Training Date",
        "Assessor Name",
        "Business Unit",
        "Assessment date",
        "Due Date(Deadline)",
        "Assessor Finished Date",
        "State",
        "Status",
        "Trainees Name",
      ],
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  computed() {
    return {};
  },
  methods: {
    checkBusinessUnit(emp_id) {
      return this.employees.find((emplist) => emplist.emp_id == emp_id)
        ?.business_unit[0]?.name;
    },
    async getAllModules() {
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.$Progress.finish();
      });
    },
    async getAssessmentResult() {
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/after-assessment-result`)
        .then((response) => {
          // console.log('respone=>',response.data.data);
          this.assessment_result = response.data.data;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#datatable").DataTable();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#datatable").DataTable().draw();
    },
    assessmentDate(date) {
      var date = new Date(date);
      date.setDate(date.getDate() - 7);
      return date.toISOString().split("T")[0];
    },
    finishedDate(created_at, updated_at) {
      if (created_at !== updated_at) {
        let date = new Date(updated_at);
        return date.toISOString().split("T")[0];
      }
      return "";
    },
    checkStatus(status) {
      if (status == 0) {
        return "Waiting";
      } else if (status == 1) {
        return "Completed";
      } else if (status == 2) {
        return "Drafted";
      }
    },
    FilterData(module, employee, state, status) {
      this.clearFilter();
      // alert('reach');
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        const check_employee = employee?.name == data[3] ? true : false;
        const check_module = module?.name == data[1] ? true : false;
        const check_state = state === data[8] ? true : false;
        const check_status = status === data[9] ? true : false;

        return (check_employee || !employee) &&
          (check_module || !module) &&
          (check_state || !state) &&
          (check_status || !status)
          ? true
          : false;
      });
      $("#datatable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();

      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#datatable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        sheetData.push(item);
      });
      exportExcel(sheetData, this.title);
      this.excelLoading = false;
    },
    refreshData() {
      this.employee = "";
      this.module = "";
      this.state = "";
      this.status = "";
      this.clearFilter();
    },
    getAllEmployees() {
      this.employees = this.$store.getters["odoo/getAllEmployees"];
      if (!this.employees.length) {
        this.loading = true;
        setTimeout(() => {
          this.getAllEmployees();
        }, 1500);
      } else {
        this.loading = false;
      }
    },
  },
  created() {
    this.clearFilter();
    this.getAllEmployees();
    this.getAssessmentResult();
    this.getAllModules();
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
